<script>
import { SuawForm, SuawButton, SuawTextInput, SuawLink, SuawInputGroup } from "@/components";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, email, maxLength, minLength, sameAs } from "vuelidate/lib/validators";
import * as UserApi from "../../api.js";
export default {
  name: "SignUpForm",
  components: { SuawForm, SuawButton, SuawTextInput, SuawLink, SuawInputGroup },
  data() {
    return {
      signupForm: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    signupForm: {
      email: { required, email },
      firstName: { required, maxLength: maxLength(40) },
      lastName: { required, maxLength: maxLength(40) },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  computed: {
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.signupForm.confirmPassword, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.signupForm.confirmPassword.sameAsPassword) return "Passwords must match.";
      return "";
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async registerUser() {
      const result = await UserApi.registerUser(this.signupForm.firstName, this.signupForm.lastName, this.signupForm.email, this.signupForm.password);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSignup() {
      this.$v.signupForm.$touch();
      if (this.$v.signupForm.$invalid) {
        this.$root.$emit("universal-error-message", "Invalid sign up form");
      } else {
        const registerUserResult = await this.registerUser();
        if (registerUserResult.success) {
          this.$router.push({
            name: "CompleteAccount",
            query: {
              id: registerUserResult.result.id,
              email: this.signupForm.email
            }
          });
        }
      }
    }
  }
};
</script>

<template>
  <SuawForm @submit="onSignup">
    <template #form>
      <SuawTextInput
        id="email-signup"
        v-model="signupForm.email"
        type="email"
        is-required
        label="Email"
        :state="formFieldState($v, 'signupForm', 'email')"
        :error-message="validateErrors($v.signupForm.email, 'Email')"
        @blur="$v.signupForm.email.$touch()"
      />
      <SuawInputGroup no-wrap>
        <SuawTextInput
          id="first-name-signup"
          v-model="signupForm.firstName"
          type="text"
          is-required
          label="First name"
          :state="formFieldState($v, 'signupForm', 'firstName')"
          :error-message="validateErrors($v.signupForm.firstName, 'First name')"
          @blur="$v.signupForm.firstName.$touch()"
        />
        <SuawTextInput
          id="last-name-signup"
          v-model="signupForm.lastName"
          type="text"
          is-required
          label="Last name"
          :state="formFieldState($v, 'signupForm', 'lastName')"
          message="Not public by default."
          :error-message="validateErrors($v.signupForm.lastName, 'Last name')"
          @blur="$v.signupForm.lastName.$touch()"
        />
      </SuawInputGroup>
      <SuawTextInput
        id="password-signup"
        v-model="signupForm.password"
        type="password"
        is-required
        label="Password"
        :state="formFieldState($v, 'signupForm', 'password')"
        :error-message="validateErrors($v.signupForm.password, 'Password')"
        @blur="$v.signupForm.password.$touch()"
      />
      <SuawTextInput
        id="password-confirm-signup"
        v-model="signupForm.confirmPassword"
        type="password"
        is-required
        label="Confirm password"
        :state="formFieldState($v, 'signupForm', 'confirmPassword')"
        :error-message="validateConfirmPasswordError"
        @blur="$v.signupForm.confirmPassword.$touch()"
      />
      <SuawButton size="large" type="primary" button-text="Sign Up" />
      <SuawLink to="/resources/contact" alignment="center">Having problems? Contact us.</SuawLink>
    </template>
  </SuawForm>
</template>
